import React from "react";
import Modal from "../Modal"; // Import the Modal component
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import "../../styles/MainFeatures/Contact.css"; // Import the CSS file for Contact

function Contact({ isOpen, onRequestClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="contact-list">
        <div className="contact-item">
          <div>
            <p className="contact-name">Razali</p>
            <p className="contact-role">Bapa / Father</p>
          </div>
          <div className="contact-icons">
            <a
              href="https://wa.me/+60123031891"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a href="tel:+60123031891">
              <FaPhoneAlt />
            </a>
          </div>
        </div>
        <div className="contact-item">
          <div>
            <p className="contact-name">Rokiah</p>
            <p className="contact-role">Ibu / Mother</p>
          </div>
          <div className="contact-icons">
            <a
              href="https://wa.me/+60127093699"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a href="tel:+60127093699">
              <FaPhoneAlt />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Contact;
