import React from "react";
import Modal from "../Modal"; // Import the Modal component
import "../../styles/MainFeatures/Wish.css"; // Import the CSS file for Gift

function Gift({ isOpen, onRequestClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} title="Rina & Akmal Wedding Wishlist">
      <div className="gift-content">
        <a href="https://wshl.st/nd4ve8">Click Here to check out our Wish List</a>
      </div>
    </Modal>
  );
}

export default Gift;
