import React from "react";
import Modal from "../Modal"; // Import the Modal component
import { FaApple, FaGoogle } from "react-icons/fa";
import "../../styles/MainFeatures/Calendar.css"; // Import the CSS file for Calendar

function Calendar({ isOpen, onRequestClose }) {
  const googleCalendarUrl =
    "https://calendar.google.com/calendar/render?action=TEMPLATE&text=Wedding+of+Rina+%26+Akmal&dates=20241026T030000Z/20241026T110000Z&details=Join+us+for+the+wedding+of+Rina+%26+Akmal.&location=Magica+Tropicana+Aman+Hall&sf=true&output=xml";

  const appleCalendarUrl = `data:text/calendar;charset=utf8,${encodeURIComponent(`
  BEGIN:VCALENDAR
  VERSION:2.0
  BEGIN:VEVENT
  DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, "")}
  DTSTART:20241026T030000Z
  DTEND:20241026T110000Z
  SUMMARY:Wedding of Rina & Akmal
  DESCRIPTION:Join us for the wedding of Rina & Akmal.
  LOCATION:Magica Tropicana Aman Hall
  END:VEVENT
  END:VCALENDAR
`)}`;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="calendar-options">
        <a href={appleCalendarUrl} download="wedding_of_rina_and_akmal.ics">
          <FaApple />
          <span>Apple Calendar</span>
        </a>
        <a href={googleCalendarUrl} target="_blank" rel="noopener noreferrer">
          <FaGoogle />
          <span>Google Calendar</span>
        </a>
      </div>
    </Modal>
  );
}

export default Calendar;
