import React, { useState, useEffect } from "react";
import "../styles/EventDetails.css";
import { database } from "../firebase"; // Import the Realtime Database instance
import { ref, onValue } from "firebase/database"; // Firebase functions to interact with the database

function EventDetails({ language }) {
  const [wishes, setWishes] = useState([]);

  useEffect(() => {
    // Reference to the 'RSVP' node in your database
    const wishesRef = ref(database, 'RSVP');

    // Fetch and listen for changes in the 'RSVP' node
    onValue(wishesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Transform the data into an array of wishes, filtering out those with empty messages
        const wishesArray = Object.values(data).filter(wish => wish.message && wish.message.trim() !== "");
        setWishes(wishesArray);
      }
    });

  }, []); // Empty dependency array means this effect runs once when the component mounts

  const calculateTimeLeft = () => {
    const eventDate = new Date("2024-10-26T00:00:00");
    const difference = eventDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="event-details">
      <div className="countdown">
        <h2 className="section-title">
          {language === "EN" ? "COUNTDOWN" : "MENGHITUNG HARI"}
        </h2>
        <div className="countdown-timer">
          <div>
            <span>{timeLeft.days}</span> {language === "EN" ? "Days" : "Hari"}
          </div>
          <div>
            <span>{timeLeft.hours}</span> {language === "EN" ? "Hours" : "Jam"}
          </div>
          <div>
            <span>{timeLeft.minutes}</span> {language === "EN" ? "Minutes" : "Minit"}
          </div>
          <div>
            <span>{timeLeft.seconds}</span> {language === "EN" ? "Seconds" : "Saat"}
          </div>
        </div>
      </div>
      <div className="wishes">
        <h2 className="section-title">
          {language === "EN" ? "WISHES" : "UCAPAN"}
        </h2>
        <div className="wishes-list">
          {wishes.map((wish, index) => (
            <div key={index} className="wish">
              <p>"{wish.message}"</p>
              <p className="sender">{wish.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="wishlist">
        <h2 className="section-title">
          {language === "EN" ? "WISHLIST" : "KEMAHUAN/KEPERLUAN"}
        </h2>
        <div className="digital-card">
          <p>
            {language === "EN"
              ? "If you wish to offer us a gift to celebrate our wedding, we greatly appreciate your kind intentions. To make it easier for you, we have provided a list of items that consist of various necessities and desires we have as newlyweds. Click the button below to view the full list of gift options."
              : "Sekiranya anda berhasrat untuk memberikan kami hadiah sempena majlis perkahwinan kami, kami amat menghargai niat baik anda. Untuk memudahkan anda, kami telah menyediakan senarai hadiah yang terdiri daripada pelbagai keperluan dan kemahuan kami sebagai pengantin baru. Klik butang di bawah untuk melihat senarai penuh pilihan hadiah."}
          </p>
          <br></br>
          <a
            href="https://wshl.st/nd4ve8"
            target="_blank"
            rel="noopener noreferrer"
          >
            {language === "EN" ? "View Wishlist" : "Lihat Wishlist"}
          </a>
        </div>
      </div>
    </div >
  );
}

export default EventDetails;
