import React, { useState } from "react";
import Modal from "../Modal";
import { FaCheckSquare, FaTimesCircle } from "react-icons/fa";
import { database } from "../../firebase"; // Import Realtime Database
import { ref, set, push } from "firebase/database"; // Realtime Database functions
import "../../styles/MainFeatures/RSVP.css";

function RSVP({ isOpen, onRequestClose, language }) {  // Accept language prop
  const [isHadirFormOpen, setIsHadirFormOpen] = useState(false);
  const [isTidakHadirFormOpen, setIsTidakHadirFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    attendance: "0 orang",
    message: "",
  });

  const handleRSVPChoice = (choice) => {
    if (choice === "hadir") {
      setIsHadirFormOpen(true);
    } else if (choice === "tidakHadir") {
      setIsTidakHadirFormOpen(true);
    }
    onRequestClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e, attendanceStatus) => {
    e.preventDefault();

    const rsvpRef = ref(database, 'RSVP');
    const newRsvpRef = push(rsvpRef);

    set(newRsvpRef, {
      ...formData,
      attendanceStatus, // Save the status (Hadir/Tidak Hadir)
      timestamp: new Date().toISOString(),
    })
      .then(() => {
        alert(language === "EN" ? "RSVP submitted successfully!" : "RSVP berjaya dihantar!");
        setFormData({
          name: "",
          phone: "",
          attendance: "1 orang",
          message: "",
        });
        setIsHadirFormOpen(false);
        setIsTidakHadirFormOpen(false);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        alert(language === "EN" ? "Failed to submit RSVP. Please try again." : "Gagal menghantar RSVP. Sila cuba lagi.");
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div className="rsvp-options">
          <button
            className="rsvp-button"
            onClick={() => handleRSVPChoice("hadir")}
          >
            <FaCheckSquare />
            <span>{language === "EN" ? "Attend" : "Hadir"}</span>
          </button>
          <button
            className="rsvp-button"
            onClick={() => handleRSVPChoice("tidakHadir")}
          >
            <FaTimesCircle />
            <span>{language === "EN" ? "Not Attending" : "Tidak Hadir"}</span>
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isHadirFormOpen}
        onRequestClose={() => setIsHadirFormOpen(false)}
        title={language === "EN" ? "Attend" : "Hadir"}
      >
        <div className="rsvp-form">
          <form onSubmit={(e) => handleSubmit(e, language === "EN" ? "Attend" : "Hadir")}>
            <label>{language === "EN" ? "Name*" : "Nama*"}</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label>{language === "EN" ? "Phone Number*" : "Nombor Telefon*"}</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label>{language === "EN" ? "Attendance Count" : "Jumlah Kehadiran"}</label>
            <select
              name="attendance"
              value={formData.attendance}
              onChange={handleChange}
            >
              <option>{language === "EN" ? "1 person" : "1 orang"}</option>
              <option>{language === "EN" ? "2 people" : "2 orang"}</option>
            </select>
            <label>{language === "EN" ? "Your Message (optional)" : "Ucapan anda (jika ada)"}</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit">{language === "EN" ? "Submit" : "Hantar"}</button>
            <button
              type="button"
              onClick={() => setIsHadirFormOpen(false)}
            >
              {language === "EN" ? "Cancel" : "Batal"}
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isTidakHadirFormOpen}
        onRequestClose={() => setIsTidakHadirFormOpen(false)}
        title={language === "EN" ? "Not Attending" : "Tidak Hadir"}
      >
        <div className="rsvp-form">
          <form onSubmit={(e) => handleSubmit(e, language === "EN" ? "Not Attending" : "Tidak Hadir")}>
            <label>{language === "EN" ? "Name*" : "Nama*"}</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label>{language === "EN" ? "Phone Number*" : "Nombor Telefon*"}</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label>{language === "EN" ? "Your Message (optional)" : "Ucapan anda (jika ada)"}</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit">{language === "EN" ? "Submit" : "Hantar"}</button>
            <button
              type="button"
              onClick={() => setIsTidakHadirFormOpen(false)}
            >
              {language === "EN" ? "Cancel" : "Batal"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default RSVP;
