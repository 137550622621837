import React from "react";
import ReactModal from "react-modal";
import "../styles/Modal.css";

ReactModal.setAppElement("#root");

function Modal({ isOpen, onRequestClose, title, children }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="overlay"
    >
      <h2>{title}</h2>
      <div className="modal-content">{children}</div>
      <button onClick={onRequestClose} className="close-button">
        Close
      </button>
    </ReactModal>
  );
}

export default Modal;
