import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Invitation from "./components/Invitation"; // Single Invitation component
import EventDetails from "./components/EventDetails";
import SplashScreen from "./components/SplashScreen";
import Footer from "./components/MainFeatures/Footer";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [showSplash, setShowSplash] = useState(true); // Show splash screen initially
  const [language, setLanguage] = useState("EN"); // Default language is English

  // Handle language selection and close the splash screen
  const handleLanguageSelect = (lang) => {
    setLanguage(lang); // Set the selected language
    setShowSplash(false); // Close the splash screen
    window.scrollTo(0, 0); // Scroll to top when splash screen is closed
  };

  // This is the main content (Header, Invitation, EventDetails, Footer)
  const mainContent = (
    <>
      <Header /> {/* No need to pass language or onLanguageChange */}
      <Invitation language={language} /> {/* Pass language as a prop */}
      <EventDetails language={language} />
      <Footer language={language} />
    </>
  );

  return (
    <Router>
      <div className="App">
        {/* Conditionally render either the SplashScreen or the main content */}
        {showSplash ? (
          <SplashScreen onLanguageSelect={handleLanguageSelect} />
        ) : (
          <Routes>
            <Route path="/" element={mainContent} />
            <Route path="/rina&mal" element={mainContent} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
