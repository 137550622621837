import React from "react";
import "../styles/SplashScreen.css";
import VidMalRina from "../vid/rinamal2.mp4";

function SplashScreen({ onLanguageSelect }) {
  return (
    <div className="splash-screen">
      <div className="splash-content">
        <video autoPlay loop muted className="splash-video">
          <source src={VidMalRina} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="language-buttons">
          <button
            className="language-button"
            onClick={() => onLanguageSelect("EN")} // Select English
          >
            ENGLISH
          </button>
          <button
            className="language-button"
            onClick={() => onLanguageSelect("BM")} // Select Bahasa Malaysia
          >
            BAHASA
          </button>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
