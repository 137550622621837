import React from "react";
import "../styles/Invitation.css";

function Invitation({ language }) {
  return (
    <div className="invitation">
      <div className="invitation-content">
        {language === "EN" ? (
          <>
            <p className="arabic">بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْمِ</p>
            <p className="greeting">Assalamualaikum W.B.T & Salam Sejahtera</p>
            <p className="main-name">ROKIAH BINTI HASSAN</p>
            <p className="amp">&</p>
            <p className="main-name">RAZALI BIN AHMAD</p>
            <p className="intro">Request the honour of your presence</p>
            <p className="guest">Dato' | Datin | Tuan | Puan | Encik | Cik</p>
            <p className="intro">
              in the celebration of the marriage of our daughter
            </p>
            <p className="child-name">NURSHAHRINA</p>
            <p className="amp">&</p>
            <p className="child-name">AMIRUL AKMAL</p>
            <hr />
            <p className="date">
              Saturday
              <br />
              October 26, 2024
              <br />
              11 AM - 5 PM
            </p>
            <p className="location">MAGICA TROPICANA AMAN HALL</p>
            <p className="location-detail">
              2, Persiaran Tropicana Aman 1,
            </p>
            <p className="location-detail">Bandar Tropicana Aman,</p>
            <p className="location-detail">42500 Telok Panglima Garang, Selangor</p>
            <br />
            <p className="rsvp">
              Please RSVP your attendance by October 11, 2024
            </p>
          </>
        ) : (
          <>
            <p className="arabic">بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْمِ</p>
            <p className="greeting">Assalamualaikum W.B.T & Salam Sejahtera</p>
            <p className="main-name">ROKIAH BINTI HASSAN</p>
            <p className="amp">&</p>
            <p className="main-name">RAZALI BIN AHMAD</p>
            <p className="intro">Dengan segala hormatnya, kami menjemput</p>
            <p className="guest">Dato' | Datin | Tuan | Puan | Encik | Cik</p>
            <p className="intro">
              ke majlis perkahwinan puteri kami yang dikasihi
            </p>
            <p className="child-name">NURSHAHRINA</p>
            <p className="amp">&</p>
            <p className="child-name">AMIRUL AKMAL</p>
            <hr />
            <p className="date">
              Sabtu
              <br />
              Oktober 26
              <br />
              11 AM - 5 PM
            </p>
            <p className="location">MAGICA TROPICANA AMAN HALL</p>
            <p className="location-detail">
              2, Persiaran Tropicana Aman 1,
            </p>
            <p className="location-detail">Bandar Tropicana Aman,</p>
            <p className="location-detail">
              42500 Telok Panglima Garang, Selangor
            </p>
            <br />
            <p className="rsvp">
              Sila RSVP Kehadiran Anda Sebelum 11 Oktober 2024
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Invitation;
