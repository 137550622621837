// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB8ygJQsL_9qlY5pGWOzvms4BWoq0P0h-M",
    authDomain: "kadkahwinrina.firebaseapp.com",
    databaseURL: "https://kadkahwinrina-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "kadkahwinrina",
    storageBucket: "kadkahwinrina.appspot.com",
    messagingSenderId: "1053086230522",
    appId: "1:1053086230522:web:2961a04761ca436ffd2643"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const database = getDatabase(app);
export { database };