import React from "react";
import Modal from "../Modal"; // Import the Modal component
import "../../styles/MainFeatures/Gift.css"; // Import the CSS file for Gift
import SquareWhite from "../../img/QRRina.jpg"; // Import the mock QR image

function Gift({ isOpen, onRequestClose, language }) {  // Accept language prop
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="gift-content">
        <div className="decorative-line"></div>
        <p className="title">{language === "EN" ? "Money Gift" : "Salam Kaut"}</p>
        <p className="name">Nurshahrina Mohd Shahrum</p>
        <p className="bank-name">MAYBANK</p>
        <p className="account-number">168621076819</p>
        <img src={SquareWhite} alt="QR Code" className="qr-image" />
        <p className="scan-qr">{language === "EN" ? "Scan QR Code" : "Imbas Kod QR"}</p>
      </div>
    </Modal>
  );
}

export default Gift;
