import React from "react";
import "../styles/Header.css";

function Header({ onLanguageChange }) {
  return (
    <header>
    </header>
  );
}

export default Header;
