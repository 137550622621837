import React from "react";
import Modal from "../Modal"; // Import the Modal component
import { FaMapMarkerAlt } from "react-icons/fa";
import "../../styles/MainFeatures/Location.css"; // Import the CSS file for Location

function Location({ isOpen, onRequestClose }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="location-options">
        <a
          href="https://www.waze.com/live-map/directions/my/selangor/telok-panglima-garang/magica-tropicana-aman-hall-(1484663-a)?to=place.ChIJv8q3K5WxzTERa0gifK054gM"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaMapMarkerAlt />
          <span>Waze</span>
        </a>
        <a
          href="https://www.google.com/maps/place/Magica+Tropicana+Aman+Hall+(1484663-A)/@2.9444983,101.5299061,17z/data=!3m1!4b1!4m6!3m5!1s0x31cdb1952bb7cabf:0x3e239ad7c22486b!8m2!3d2.9444929!4d101.532481!16s%2Fg%2F11rn0bvb55?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaMapMarkerAlt />
          <span>Google Map</span>
        </a>
      </div>
    </Modal>
  );
}

export default Location;
